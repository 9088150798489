body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./Assets/Fonts/Raleway-ExtraBold.ttf) format("truetype");
}

a:link {
  text-decoration: none;
  color: #053868;
}
a:visited {
  text-decoration: none;
  color: #053868;
}
a:hover {
  text-decoration: none;
  color: #053868;
}
a:active {
  text-decoration: none;
  color: #053868;
}

.parallax-layer {
  background-color: #053868;
}

.p-content-layer {
  display: flex;
  justify-content: center;
  align-items: center;
}
